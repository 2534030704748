







  import { Component, Vue } from 'nuxt-property-decorator'
  import { namespace } from 'vuex-class'
  // @ts-ignore
  import { NavigationGuardNext, Route } from 'vue-router/types/router'

  const Layout = namespace('layout')
  const cms = namespace('cms')
  const account = namespace('account')

  @Component({
    middleware: ['isAuthenticatedOrPlatformOpened'],
    components: {
      OneCmsGridRender: () => import('~/components/molecules/renders/OneCmsGridRender.vue'),
    },
  })
  export default class Index extends Vue {
    @Layout.Mutation('TOGGLE_BREADCRUMBS') toggleBreadcrumbs: any;
    @Layout.Mutation('TOGGLE_BACKDROP') backdropShow: any;

    @cms.Getter configuration: any;
    @account.Getter getClient: any;
    mainComponentClass = 'one-index-page'

    mounted() {
      this.$plugins.onMounted(this.mainComponentClass, this)
    }

    head() {
      return {
        script: [{
          type: 'application/ld+json',
          json: {
            '@context': 'https://schema.org',
            '@type': 'WebPage',
            publisher: {
              name: this.configuration.tenantKey,
            },
            mainEntity: {
              '@type': 'Person',
              email: this?.loggedUser?.invoiceEmail,
              name: this?.loggedUser.name,
              telephone: this?.loggedUser.phone,
            },
          },
        }],
      }
    }

    availableComponents: any = {
      html: () => import('~/components/organisms/grid/shared/OneCmsDynamicComponent.vue'),
      staticHtml: () => import('~/components/organisms/grid/shared/OneCmsStaticComponent.vue'),
      image: () => import('~/components/molecules/images/OneCmsGridImageComponent.vue'),
      link: () => import('~/components/molecules/links/OneCmsGridLinkComponent.vue'),
      Category: () => import('~/components/organisms/grid/content/OneCmsCategoryOverview.vue'),
      productContainer: () => import('~/components/organisms/grid/content/OneCmsProductContainer.vue'),
      TopProducts: () => import('~/components/organisms/grid/content/OneCmsTopProducts.vue'),
      StaticBanners: () => import('~/components/organisms/grid/content/OneCmsStaticBanners.vue'),
      carouselWithCategories: () => import('~/components/organisms/grid/content/OneCmsCategoriesListWithBanner.vue'),
      listWithMegapopup: () => import('~/components/organisms/grid/content/OneCmsCategoriesMegadropdown.vue'),
      customizableProductsRotator: () => import('~/components/organisms/grid/content/OneCmsProductsRotator.vue'),
      carouselWithThumbnails: () => import('~/components/organisms/grid/content/OneCmsCarouselWithThumbnails.vue'),
      youtube: () => import('~/components/organisms/grid/shared/OneCmsYoutubeComponent.vue'),
      textEditor: () => import('~/components/organisms/grid/shared/OneCmsTextEditorComponent.vue'),
      widgetSeparator: () => import('~/components/atoms/OneCmsWidgetSeparator.vue'),
      gridArticle: () => import('~/components/organisms/grid/content/OneCmsArticle.vue'),
    }

    get content() {
      return this.configuration && this.configuration.content
    }

    get loggedUser() {
      return this.getClient ?? null
    }

    fetch({ store }: any) {
      return store.commit('layout/TOGGLE_BREADCRUMBS', false)
    }

    destroyed() {
      this.backdropShow(false)
      this.$plugins.onUnmounted(this.mainComponentClass)
    }

    beforeRouteEnter(_to: Route, _from: Route, next: NavigationGuardNext) {
      return next((vm: Vue) => {
        vm.$store.dispatch('products/clearSearchAndListing')
      })
    }
  }
